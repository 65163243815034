@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
[data-theme='light'],
[data-mode='light'] {
  /* PRIMITIVE TOKENS */
  --neutral-0: #ffffff;
  --neutral-25: #fdfcfd;
  --neutral-50: #f9fafb;
  --neutral-100: #f2f4f7;
  --neutral-200: #e4e7ec;
  --neutral-300: #d0d5dd;
  --neutral-400: #98a2b3;
  --neutral-500: #929197;
  --neutral-600: #475467;
  --neutral-700: #3a424f;
  --neutral-800: #222835;
  --neutral-900: #151920;
  --neutral-1000: #0e0f13;

  --indigo-25: #eeeeff;
  --indigo-50: #c9c9fa;
  --indigo-100: #aeaef7;
  --indigo-200: #9493f4;
  --indigo-300: #7978f2;
  --indigo-400: #5e5def;
  --indigo-500: #4e4ec7;
  --indigo-600: #3f3e9f;
  --indigo-700: #2f2f78;
  --indigo-800: #1f1f50;
  --indigo-900: #1c0733;

  --purple-25: #fcfaff;
  --purple-50: #f9f5ff;
  --purple-100: #f4ebff;
  --purple-200: #e9d7fe;
  --purple-300: #d6bbfb;
  --purple-400: #b692f6;
  --purple-500: #9e77ed;
  --purple-600: #7f56d9;
  --purple-700: #6941c6;
  --purple-800: #53389e;
  --purple-900: #42307d;
  --purple-1000: #261756;

  --red-50: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;
  --red-950: #450a0a;

  --emerald-50: #ecfdf5;
  --emerald-100: #d1fae5;
  --emerald-200: #a7f3d0;
  --emerald-300: #6ee7b7;
  --emerald-400: #34d399;
  --emerald-500: #10b981;
  --emerald-600: #059669;
  --emerald-700: #047857;
  --emerald-800: #065f46;
  --emerald-900: #064e3b;
  --emerald-950: #022c22;

  --pink-50: #fdf2f8;
  --pink-100: #fce7f3;
  --pink-200: #fbcfe8;
  --pink-300: #f9a8d4;
  --pink-400: #f472b6;
  --pink-500: #ec4899;
  --pink-600: #db2777;
  --pink-700: #be185d;
  --pink-800: #9d174d;
  --pink-900: #831843;
  --pink-950: #500724;

  --rose-50: #fff1f2;
  --rose-100: #ffe4e6;
  --rose-200: #fecdd3;
  --rose-300: #fda4af;
  --rose-400: #fb7185;
  --rose-500: #f43f5e;
  --rose-600: #e11d48;
  --rose-700: #be123c;
  --rose-800: #9f1239;
  --rose-900: #881337;
  --rose-950: #4c0519;

  --amber-50: #fffbeb;
  --amber-100: #fef3c7;
  --amber-200: #fde68a;
  --amber-300: #fcd34d;
  --amber-400: #fbbf24;
  --amber-500: #f59e0b;
  --amber-600: #d97706;
  --amber-700: #b45309;
  --amber-800: #92400e;
  --amber-900: #78350f;
  --amber-950: #451a03;

  --cyan-50: #ecfeff;
  --cyan-100: #cffafe;
  --cyan-200: #a5f3fc;
  --cyan-300: #67e8f9;
  --cyan-400: #22d3ee;
  --cyan-500: #06b6d4;
  --cyan-600: #0891b2;
  --cyan-700: #0e7490;
  --cyan-800: #155e75;
  --cyan-900: #164e63;
  --cyan-950: #083344;

  /* Component Specific token */
  --color-text-primary: var(--neutral-900);
  --color-text-primary-soft: var(--neutral-800);
  --color-text-invert-primary: var(--neutral-0);
  --color-text-secondary: var(--neutral-400);
  --color-text-tertiary: var(--neutral-300);
  --color-text-accent: var(--indigo-400);
  --color-text-on-accent: var(--neutral-0);
  --color-text-success: var(--emerald-400);
  --color-text-danger: var(--rose-500);
  --color-text-feature-form: var(--amber-500);
  --color-text-feature-linkinbio: var(--pink-500);
  --color-text-feature-landingpage: var(--indigo-400);
  --color-text-feature-blog: var(--cyan-500);
  --color-text-feature-email: var(--rose-500);
  --color-text-feature-product: var(--emerald-600);
  --color-text-feature-ai: var(--neutral-100);

  --color-text-landing-templates: #312358;

  --color-background: var(--neutral-0);
  --color-background-button-accent-soft: var(--indigo-25);
  --color-background-secondary: var(--neutral-50);
  --color-background-tertiary: var(--neutral-100);
  --color-background-surface: var(--neutral-300);
  --color-background-accent: var(--indigo-400);
  --color-background-modal: var(--neutral-0);
  --color-background-overlay: #9b9aa080;
  --color-background-button-invert: var(--neutral-1000);
  --color-background-highlight: #25222e0a;
  --color-background-button-danger: var(--red-500);
  --color-background-button-danger-soft: var(--red-50);
  --color-background-success: var(--emerald-400);
  --color-background-success-soft: var(--emerald-50);
  --color-background-feature-form: var(--amber-100);
  --color-background-feature-linkinbio: var(--pink-100);
  --color-background-feature-landingpage: var(--indigo-25);
  --color-background-feature-blog: var(--cyan-100);
  --color-background-feature-email: var(--rose-100);
  --color-background-feature-product: var(--emerald-100);
  --color-background-feature-ai: rgb(10, 10, 10);

  --color-background-landing-templates: #f3f1ff;

  --color-border: var(--neutral-200);
  --color-border-darken: #10182833;
  --color-border-accent: var(--indigo-400);
  --color-border-soft: var(--neutral-100);
  --color-border-danger: var(--red-500);
  --color-border-neutral: var(--neutral-800);
  --color-border-feature-form: var(--amber-400);
  --color-border-feature-linkinbio: var(--pink-300);
  --color-border-feature-landingpage: var(--indigo-300);
  --color-border-feature-blog: var(--cyan-300);
  --color-border-feature-email: var(--rose-300);
  --color-border-feature-product: var(--emerald-300);
  --color-border-button: var(--neutral-300);

  --color-hr: var(--neutral-200);

  --color-gradient-feature-ai-1: #6245fc;
  --color-gradient-feature-ai-2: #a944ec;
  --color-gradient-feature-ai-3: #79d8d2;

  --color-gradient-landing-templates-1: #f3f1ff;
  --color-gradient-landing-templates-2: #f5f9fe;
  --color-gradient-landing-templates-3: #5e5def;
  --color-gradient-landing-templates-4: #2e2db8;

  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-sm: 0px 1px 5px rgba(16, 24, 40, 0.07), 0px 1px 2px rgba(16, 24, 40, 0.04);
  --shadow-md: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  --shadow-lg: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  --shadow-xl: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  --shadow-xl-soft: 0px 15px 15px -8px rgba(16, 24, 40, 0.04),
    0px 40px 48px -8px rgba(16, 24, 40, 0.1);
}

[data-theme='dark'],
[data-mode='dark'] {
  /* Component Specific token */
  --color-text-primary: var(--neutral-0);
  --color-text-primary-soft: var(--neutral-100);
  --color-text-invert-primary: var(--neutral-900);
  --color-text-secondary: var(--neutral-400);
  --color-text-tertiary: var(--neutral-600);
  --color-text-accent: var(--indigo-400);
  --color-text-on-accent: var(--neutral-0);
  --color-text-success: var(--emerald-400);
  --color-text-danger: var(--rose-500);
  --color-text-feature-form: var(--amber-500);
  --color-text-feature-linkinbio: var(--pink-500);
  --color-text-feature-landingpage: var(--indigo-300);
  --color-text-feature-blog: var(--cyan-500);
  --color-text-feature-email: var(--rose-500);
  --color-text-feature-product: var(--emerald-300);
  --color-text-feature-ai: var(--neutral-100);

  --color-text-landing-templates: #312358;

  --color-background: var(--neutral-1000);
  --color-background-button-accent-soft: var(--indigo-900);
  --color-background-secondary: var(--neutral-900);
  --color-background-tertiary: var(--neutral-800);
  --color-background-surface: var(--neutral-700);
  --color-background-accent: var(--indigo-400);
  --color-background-modal: var(--neutral-1000);
  --color-background-overlay: #00000080;
  --color-background-button-invert: var(--neutral-0);
  --color-background-highlight: #e9e9ff0a;
  --color-background-button-danger: var(--red-400);
  --color-background-button-danger-soft: var(--red-950);
  --color-background-success: var(--emerald-400);
  --color-background-success-soft: var(--emerald-950);
  --color-background-feature-form: var(--amber-950);
  --color-background-feature-linkinbio: var(--pink-950);
  --color-background-feature-landingpage: var(--indigo-900);
  --color-background-feature-blog: var(--cyan-950);
  --color-background-feature-email: var(--rose-950);
  --color-background-feature-product: var(--emerald-950);
  --color-background-feature-ai: rgb(10, 10, 10);

  --color-background-landing-templates: #f3f1ff;

  --color-border: var(--neutral-800);
  --color-border-soft: var(--neutral-900);
  --color-border-neutral: var(--neutral-100);
  --color-border-feature-form: var(--amber-800);
  --color-border-feature-linkinbio: var(--pink-800);
  --color-border-feature-landingpage: var(--indigo-600);
  --color-border-feature-blog: var(--cyan-700);
  --color-border-feature-email: var(--emerald-700);
  --color-border-feature-product: var(--emerald-600);
  --color-border-button: var(--neutral-700);

  --color-hr: var(--neutral-800);

  --color-gradient-feature-ai-1: #6245fc;
  --color-gradient-feature-ai-2: #a944ec;
  --color-gradient-feature-ai-3: #79d8d2;

  --color-gradient-landing-templates-1: #f3f1ff;
  --color-gradient-landing-templates-2: #f5f9fe;
  --color-gradient-landing-templates-3: #5e5def;
  --color-gradient-landing-templates-4: #2e2db8;
}
